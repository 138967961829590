import { useReducer, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { appReducer, initialState, AppContext } from "./appInfo";
import checkVersionAndUpdateCache from './version';

import PantallaHome from './views/PantallaHome'
import PantallaInfo from './views/PantallaInfo'
import PantallaCalendario from './views/PantallaCalendario'
import PantallaUnidades from './views/PantallaUnidades'
import PantallaMision from './views/PantallaMision'
import PantallaCuestionario from './views/PantallaCuestionario'
import PantallaDesafio from './views/PantallaDesafio'
import PantallaDevoluciones from './views/PantallaDevoluciones'
import PantallaDevolucionAlumno from './views/PantallaDevolucionAlumno'
import PantallaDevolucionProfesor from './views/PantallaDevolucionProfesor'
import PantallaProgreso from './views/PantallaProgreso'
import PantallaProgresoExperiencia from './views/PantallaProgresoExperiencia'
import PantallaMetricas from './views/PantallaMetricas'
import PantallaRanking from './views/PantallaRanking'
import PantallaVideos from './views/PantallaVideos'
import PantallaApunteDownload from './views/PantallaApunteDownload'
import PantallaEncuesta from './views/PantallaEncuesta'
import PantallaEncuestaResultados from './views/PantallaEncuestaResultados'

import {EnrollmentDetailAPI} from "./api/EnrollmentDetailAPI"
import PantallaApuntes from "./views/PantallaApuntes";

import ResponsiveDrawer from "./components/ResponsiveDrawer";
import PantallaCuestionarioSolucion from "./views/PantallaCuestionarioSolucion";

function App() {
  const [state, dispatch] = useReducer(appReducer, initialState);

  useEffect(() => {
    //checkVersionAndUpdateCache();
    currentUser();
  }, [])

  const currentUser = () => {
    const params = new URLSearchParams(window.location.search);
    let path = window.location.pathname;
    let pathParts = path.split('/');
    let endpoint = pathParts[1];
    
    let course = "";
    if(endpoint === "cursos" && pathParts[2]) {
      // Entro si el curso está definido en el endpoint de la URL
      // ejemplo: /cursos/pi-6/
      course = pathParts[2];
    }
    else {
      if (params.has('course') === true) {
        // Entro si el curso está definido en los parámetros de la URL
        // Ejemplo: "Python Inicial [ID: PI-16-20221107]"
        let target = params.get('course');
        let pattern = /\[ID: ([\w-]+)\]/;
        let match = pattern.exec(target);
        if (match) {
          let parts = match[1].split("-");
          if (parts.length >= 2) {
            course = parts[0].toLowerCase() + "-" + parts[1];
          }
        }
      }
    }
    
    let username = "";
    if (params.has('username') === true) {
      username = params.get('username');
      // Almacenar el nuevo username en el local storage
      localStorage.desafiosUsername = username
    }
    else {
      // Leer el último valor de true almacenado en memoria
      username = localStorage.desafiosUsername? localStorage.desafiosUsername : ""
    }

    //console.log(`course ${course} username ${username}`);
    
    dispatch({type: "setUsername", payload: username})
    dispatch({type: "setUserview", payload: username})
    dispatch({type: "setCourse", payload: course})

    if(username === "" || course === "") {
      if(window.location.pathname !== "/") {
        window.location.href = "/";
      }
    }
    else {
      // El nombre y usuairo está definido, busco más información
      // al respuesto en el enrollment
      EnrollmentDetailAPI.getAll(username, course).then((enrollment) => {
        // dispatch({type: "setUsername", payload: enrollment.username})
        // dispatch({type: "setCourse", payload: enrollment.course})
        dispatch({type: "setIsStaff", payload: enrollment.is_staff})
      });
      return () => {
        //StudenUnitAPI.abort() // cancel request
      }
    }

  }

  return (
    <AppContext.Provider value={{ state, dispatch }}>
    <Routes>
      <Route path="/" element={<ResponsiveDrawer />}>
        <Route path='/' element={<PantallaHome />} />
        <Route path='/cursos/:course/info' element={<PantallaInfo />} />
        <Route path='/cursos/:course/calendario' element={<PantallaCalendario />} />
        <Route path='/cursos/:course/unidades' element={<PantallaUnidades />} />
        <Route path='/cursos/:course/unidades/:uidx/apuntes' element={<PantallaApuntes />} />
        <Route path='/cursos/:course/unidades/:uidx/link/:type/id/:rid' element={<PantallaApunteDownload />} />
        <Route path='/cursos/:course/unidades/:uidx/link/:type' element={<PantallaApunteDownload />} />
        <Route path='/cursos/:course/progreso' element={<PantallaProgreso />} />
        <Route path='/cursos/:course/progreso/experiencia' element={<PantallaProgresoExperiencia />} />
        <Route path='/cursos/:course/ranking' element={<PantallaRanking />} />
        <Route path='/cursos/:course/metricas' element={<PantallaMetricas />} />
        <Route path='/cursos/:course/devoluciones' element={<PantallaDevoluciones />} />
        <Route path='/cursos/:course/videos' element={<PantallaVideos />} />

        <Route path='/cursos/:course/misiones/:qid' element={<PantallaMision />} />
        <Route path='/cursos/:course/cuestionarios/:qid' element={<PantallaCuestionario />} />
        <Route path='/cursos/:course/cuestionarios/:qid/solucion' element={<PantallaCuestionarioSolucion />} />

        <Route path='/cursos/:course/desafios/:cid' element={<PantallaDesafio />} />

        <Route path='/cursos/:course/devoluciones/alumno/:fid' element={<PantallaDevolucionAlumno />} />
        <Route path='/cursos/:course/devoluciones/profesor/:fid' element={<PantallaDevolucionProfesor />} />

        <Route path='/cursos/:course/encuestas/:qid' element={<PantallaEncuesta />} />
        <Route path='/cursos/:course/encuestas/:qid/resultados' element={<PantallaEncuestaResultados />} />

        <Route exact path='/cursos/:course' element={<Navigate to='cursos/:course/unidades' />} />
      </Route>
      <Route exact path='*' element={<Navigate to='/' />} />
    </Routes>
    </AppContext.Provider>
  );
}



export default App;
